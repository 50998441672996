export const redirectWithFallback = (deepLink: string, backupLink: string) => {
  const timer = setTimeout(() => {
    // Redirect to fallback if the app isn't opened
    window.location.href = backupLink
  }, 1500) // Adjust timeout based on expected app launch time

  // Attempt to open the app
  window.location.replace(deepLink)

  // Clear timer if the app is opened
  window.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'hidden') {
      clearTimeout(timer)
    }
  })
}
