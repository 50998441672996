/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable max-lines */
/* eslint-disable max-len */
/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable react/jsx-child-element-spacing */
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { TabContext, TabPanel } from '@mui/lab'
import { Avatar, Button, Checkbox, darken, FormControl, FormControlLabel, IconButton, lighten, Link, Slide, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'
import { isAndroid, isIOS } from 'react-device-detect'

import { appleMusicSubscribeLink, getAmazonSubscribeLink, getDeezerSubscribeLink, getSpotifySubscribeLink } from 'src/api/subscribe'
import { GetColorLink } from 'src/components/pages/SmartLink/Content/Functions/GetColorLink'
import GetIcon from 'src/components/pages/SmartLink/Content/Functions/GetIcon'
import DialogDrawer from 'src/components/pages/SmartLink/DialogDrawer/DialogDrawer'
import { redirectWithFallback } from 'src/components/pages/SmartLink/Shared/RedirectWithFallback'
import type { MarketingLink } from 'src/models/Marketing'
import type { BasicOrganisation } from 'src/models/Organisation'
import * as darkTheme from 'src/styles/dark.theme'
import * as lightTheme from 'src/styles/light.theme'

// eslint-disable-next-line unicorn/no-unsafe-regex
const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/

type Props = {
  close: () => void
  open: boolean
  openDrawer: () => void
  link: MarketingLink | undefined
  organisation: BasicOrganisation
}

const SubscribeModal = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))

  const [acceptTerms, setAcceptTerms] = useState(false)
  const [email, setEmail] = useState('')
  const [tab, setTab] = useState('home')
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  const activeTheme = props.link?.palette === 'Dark'
    ? darkTheme
    : lightTheme

  const handleCheckboxTerms = () => {
    setAcceptTerms(!acceptTerms)
  }

  const appleMusicSubscribe = async () => {
    if (emailRegex.test(email)) {
      setError(false)
      setLoading(true)
      if (props.link?.organisationId) {
        const music = MusicKit.getInstance()
        await music.authorize()
          .then(async musicUserToken => {
            if (props.link) {
              await appleMusicSubscribeLink(props.link.organisationId, musicUserToken, email)
              window.location.href = `${window.location.href}?subscribed=true`
            }
          })
          .finally(() => setLoading(false))
      }
    } else {
      setError(true)
    }
  }

  const spotifySubscribe = async () => {
    if (props.link?.organisationId && props.link.id) {
      await getSpotifySubscribeLink(props.link.organisationId, props.link.id)
        .then(link => {
          const urlParams = new URLSearchParams(link.replace('https://accounts.spotify.com/authorize', ''))
          const spotifyAccountAuthorizeLink = link
          const redirectUri = 'https%3A%2F%2Fapi.releese.io%2Fapi%2Fsubscribe%2Fspotify'
          const scopes = urlParams.get('scope')
          const clientId = urlParams.get('client_id')
          const state = urlParams.get('state')

          if (isAndroid) {
            const intentLocationAndroid = `intent://accounts.spotify.com/inapp-authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&scope=${scopes}&state=${state}#Intent;package=com.spotify.music;scheme=https;end`
            redirectWithFallback(intentLocationAndroid, spotifyAccountAuthorizeLink)
          } else if (isIOS) {
            const intentLocationIOS = `spotify-action://authorize?cc=0&response_type=code&client_id=${clientId}&scope=${scopes}&redirect_uri=${redirectUri}&state=${state}`
            redirectWithFallback(intentLocationIOS, spotifyAccountAuthorizeLink)
          } else {
            window.location.href = spotifyAccountAuthorizeLink
          }
        })
    }
  }

  const deezerSubscribe = async () => {
    if (props.link?.organisationId && props.link.id) {
      await getDeezerSubscribeLink(props.link.organisationId, props.link.id)
        .then(link => {
          window.location.href = link
        })
    }
  }

  const amazonSubscribe = async () => {
    if (props.link?.organisationId && props.link.id) {
      await getAmazonSubscribeLink(props.link.organisationId, props.link.id)
        .then(link => {
          window.location.href = link
        })
    }
  }

  return (
    <DialogDrawer
      close={props.close}
      isOpen={props.open}
      link={props.link}
      open={props.openDrawer}
    >
      <Stack paddingX={3} paddingY={3} spacing={4} width={1}>
        <Stack alignItems='center' spacing={2} width='100%'>
          {!fullScreen &&
          <Stack alignItems='flex-end' width={1}>
            <IconButton
              onClick={() => props.close()}
              sx={{
                width: 'fit-content',
              }}
            >
              <CloseRoundedIcon />
            </IconButton>
          </Stack>}
          <Stack alignItems='center' width='100%'>
            <Avatar
              src={props.organisation.logoUrl}
              sx={{
                height: 128,
                width: 128,
              }}
            />
            <Typography
              sx={{
                color: activeTheme.default.palette.text.primary,
              }}
              textAlign='center'
              variant='h3'
            >
              {`Subscribe to ${props.organisation.name}`}
            </Typography>
            <Typography
              sx={{
                color: activeTheme.default.palette.text.secondary,
              }}
              textAlign='center'
              variant='body1'
            >
              {`By subscribing, you will pre-save all future music released by ${props.organisation.name}`}
            </Typography>
          </Stack>
          <Stack alignItems='center' width='100%'>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={acceptTerms}
                    onChange={handleCheckboxTerms}
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                  />
                }
                label={<Typography
                  sx={{
                    color: activeTheme.default.palette.text.label,
                  }}
                  variant='helperText'
                >
                  {`I agree to share my email and personal information for 
marketing purposes to ${props.organisation.name} 
                  and accept the `}
                  <Link href='https://releese.io/legal/terms-and-conditions' target='_blank'>
                    Terms and conditions
                  </Link>
                  {' and the '}
                  <Link href='https://releese.io/legal/privacy-policy' target='_blank'>
                    Privacy policy.
                  </Link>
                </Typography>}
              />
            </FormControl>
          </Stack>
        </Stack>

        <TabContext value={tab}>
          <TabPanel sx={{ padding: 0 }} value='home'>
            <Stack spacing={1.5} width={1}>
              <Button
                disabled={!acceptTerms}
                fullWidth
                key='Spotify-Subscribe'
                onClick={() => void spotifySubscribe()}
                startIcon={GetIcon('Spotify')}
                sx={{
                  backgroundColor: GetColorLink('Spotify'),
                  ':hover': {
                    backgroundColor: darken(GetColorLink('Spotify'), 0.2),
                  },
                  '.MuiButton-startIcon': {
                    position: 'absolute',
                    left: 16,
                  },
                }}
              >
                Subscribe on Spotify
              </Button>
              <Button
                disabled={!acceptTerms}
                fullWidth
                id='apple-music-authorize'
                key='Apple-Music-Subscribe'
                onClick={() => setTab('apple')}
                startIcon={GetIcon('Apple Music')}
                sx={{
                  backgroundColor: GetColorLink('Apple Music'),
                  ':hover': {
                    backgroundColor: darken(GetColorLink('Apple Music'), 0.2),
                  },
                  '.MuiButton-startIcon': {
                    position: 'absolute',
                    left: 16,
                  },
                }}
              >
                Subscribe on Apple Music
              </Button>
              <Button
                disabled={!acceptTerms}
                fullWidth
                key='Deezer-Subscribe'
                onClick={() => void deezerSubscribe()}
                startIcon={GetIcon('Deezer')}
                sx={{
                  backgroundColor: GetColorLink('Deezer'),
                  ':hover': {
                    backgroundColor: darken(GetColorLink('Deezer'), 0.2),
                  },
                  '.MuiButton-startIcon': {
                    position: 'absolute',
                    left: 16,
                  },
                }}
              >
                Subscribe on Deezer
              </Button>
              <Button
                disabled={!acceptTerms}
                fullWidth
                key='Amazon-Subscribe'
                onClick={() => void amazonSubscribe()}
                startIcon={GetIcon('Amazon')}
                sx={{
                  backgroundColor: GetColorLink('Amazon'),
                  ':hover': {
                    backgroundColor: darken(GetColorLink('Amazon'), 0.2),
                  },
                  '.MuiButton-startIcon': {
                    position: 'absolute',
                    left: 16,
                  },
                }}
              >
                Subscribe on Amazon Music
              </Button>
            </Stack>
          </TabPanel>
          <Slide direction='right' in={tab === 'apple'} mountOnEnter unmountOnExit>
            <TabPanel sx={{ padding: 0 }} value='apple'>
              <Stack spacing={1.5} width={1}>
                <TextField
                  autoComplete='new-password'
                  disabled={loading}
                  error={error}
                  label='Email'
                  onChange={event => setEmail(event.target.value)}
                  placeholder='me@example.com'
                  value={email}
                />
                <Button
                  fullWidth
                  id='apple-music-authorize'
                  key='Apple-Music-Subscribe'
                  onClick={() => void appleMusicSubscribe()}
                  startIcon={GetIcon('Apple Music')}
                  sx={{
                    backgroundColor: GetColorLink('Apple Music'),
                    ':hover': {
                      backgroundColor: darken(GetColorLink('Apple Music'), 0.2),
                    },
                    '.MuiButton-startIcon': {
                      position: 'absolute',
                      left: 16,
                    },
                  }}
                >
                  Subscribe on Apple Music
                </Button>
                <Button
                  fullWidth
                  onClick={() => setTab('home')}
                  sx={{
                    color: activeTheme.default.palette.text.primary,
                    background: activeTheme.default.palette.background.default,
                    ':hover': {
                      backgroundColor: props.link?.palette === 'Dark'
                        ? lighten(activeTheme.default.palette.background.default, 0.2)
                        : darken(activeTheme.default.palette.background.default, 0.2),
                    },
                  }}
                  variant='text'
                >
                  Return
                </Button>
                {error &&
                <Typography sx={{ color: activeTheme.default.palette.text.secondary }} variant='body1'>
                  We collect email addresses to send unsubscribe instructions and pre-save notifications. You can
                  unsubscribe at any time.
                </Typography>}
              </Stack>
            </TabPanel>
          </Slide>
        </TabContext>
      </Stack>
    </DialogDrawer>
  )
}

export default SubscribeModal
